body {
  font-family: Lato;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Catamaran;
  font-weight: 800 !important;
}

.btn-xl {
  text-transform: uppercase;
  letter-spacing: .1rem;
  padding: 1.5rem 3rem;
  font-size: .9rem;
  font-weight: 700;
}

.bg-black {
  background-color: #000 !important;
}

.rounded-pill {
  border-radius: 5rem;
}

.navbar-custom {
  background-color: #000000b3;
}

.navbar-custom .navbar-brand {
  text-transform: uppercase;
  letter-spacing: .1rem;
  font-size: 1rem;
  font-weight: 700;
}

.navbar-custom .navbar-nav .nav-item .nav-link {
  text-transform: uppercase;
  letter-spacing: .1rem;
  padding-top: 0;
  font-size: .8rem;
  font-weight: 700;
}

header.masthead {
  background: linear-gradient(0deg, #f87731 0%, #d4156e 100%) center / cover no-repeat;
  padding-top: calc(7rem + 72px);
  padding-bottom: 7rem;
  position: relative;
  overflow: hidden;
}

header.masthead .masthead-content {
  z-index: 1;
  position: relative;
}

header.masthead .masthead-content .masthead-heading {
  font-size: 4rem;
}

header.masthead .masthead-content .masthead-subheading {
  font-size: 2rem;
}

header.masthead .bg-circle {
  z-index: 0;
  background: linear-gradient(0deg, #d4156e 0%, #f87731 100%);
  border-radius: 100%;
  position: absolute;
}

header.masthead .bg-circle-1 {
  width: 90rem;
  height: 90rem;
  bottom: -55rem;
  left: -55rem;
}

header.masthead .bg-circle-2 {
  width: 50rem;
  height: 50rem;
  top: -25rem;
  right: -25rem;
}

header.masthead .bg-circle-3 {
  width: 20rem;
  height: 20rem;
  bottom: -10rem;
  right: 5%;
}

header.masthead .bg-circle-4 {
  width: 30rem;
  height: 30rem;
  top: -5rem;
  right: 35%;
}

@media (width >= 992px) {
  header.masthead {
    padding-top: calc(10rem + 55px);
    padding-bottom: 10rem;
  }

  header.masthead .masthead-content .masthead-heading {
    font-size: 6rem;
  }

  header.masthead .masthead-content .masthead-subheading {
    font-size: 4rem;
  }
}

.bg-primary {
  background-color: #d4156e !important;
}

.btn-primary {
  background-color: #d4156e;
  border-color: #d4156e;
}

.btn-primary:active, .btn-primary:focus, .btn-primary:hover {
  background-color: #a61056 !important;
  border-color: #a61056 !important;
}

.btn-primary:focus {
  box-shadow: 0 0 0 .2rem #d4156e80;
}

.btn-secondary {
  background-color: #f87731;
  border-color: #f87731;
}

.btn-secondary:active, .btn-secondary:focus, .btn-secondary:hover {
  background-color: #ee5908 !important;
  border-color: #ee5908 !important;
}

.btn-secondary:focus {
  box-shadow: 0 0 0 .2rem #f8773180;
}

.navbarpad {
  margin-top: 6rem;
}

.userphoto {
  max-width: 24px;
  max-height: 24px;
}

.clickable {
  cursor: pointer;
}

.card, .card-tag, .card-note {
  color: #000;
  background-color: #fbd9e9;
  box-shadow: 10px 10px 5px gray;
}

.card-note {
  margin-bottom: 1rem;
}

.card-tag {
  background-color: #f9c3dc;
  border: 2px solid #f8b3d3;
  margin-bottom: 1rem;
}

a {
  color: #2e6db4;
}

a:hover {
  color: #6198d7;
}

body {
  color: #fff;
  background-color: #ccc;
}

.card-footer {
  text-align: center;
}

.notebook-name {
  color: #f87731;
  font-family: Lato;
}

.notebook-name:hover {
  color: #fbad83;
}
/*# sourceMappingURL=base.b0fda615.css.map */
