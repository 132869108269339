// Core variables and mixins
@import "variables.scss";
@import "mixins.scss";
// Global CSS
@import "global.scss";
// Components
@import "navbar.scss";
@import "masthead.scss";
@import "bootstrap-overrides.scss";
.navbarpad {
	margin-top: 6rem;
}

.userphoto {
	max-width: 24px;
	max-height: 24px;
}
.clickable { cursor:pointer; }
.card {
	//border: 3px solid $primary; // moving to box-shadow instead
	//border-radius: 1.2rem;
	box-shadow: 10px 10px 5px grey;
	background-color: scale-color($primary, $lightness:85%);
	color:black;
}

.card-note {
	@extend .card;
	margin-bottom: 1rem;
}

.card-tag {
	@extend .card;
	background-color: scale-color($primary, $lightness:76%);
	border: 2px solid scale-color($primary, $lightness:70%);
	margin-bottom: 1rem;
}

a { color: $link-color; }
a:hover {color: $link-hover-color; }
body {
	background-color: scale-color(#000, $lightness:80%);
	color: white;
}
.card-footer {
	text-align: center;
}
.notebook-name {
	font-family: Lato;
	color: $secondary;
}
.notebook-name:hover {
	color: scale-color($secondary, $lightness:40%);
}